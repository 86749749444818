import cross from "../../assets/images/cross.svg"
import deposit from "../../assets/images/deposit.svg"
import { useEffect, useState } from "react"
import axios from "axios";
import { BASE_URL } from "../../constanta/apiConfig";
import Keyboard from "./Keyboard";
import { showError, showInfo, showWaitPay } from "../../constanta/Toastr";

function Deposit({toStepSecondDeposit, pays, setPays, close, summ, setSumm, setReqDeposit, setSummDepositSec}){
    const [openKeyboard, setOpenKeyboard] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const changePays = (clickedIndex) => {
        setPays(pays.map((pay, index) => {
            return index === clickedIndex
                ? { ...pay, active: true }
                : { ...pay, active: false };
        }));
    }

    

    useEffect(()=>{
        axios.post(`${BASE_URL}/api/get-pays`,{}, { withCredentials: true })
        .then(res => {
            setPays(res.data)
        })
    }, [])

    const activePay = pays.find(pay => pay.active);

    const pay = () => {
        
        if(summ.length == 0){
            showError("Уведите суму депозита")
        }else if(summ.length > 6){
            //error
        }
        else{
            axios.post(`${BASE_URL}/api/create-deposit`,{
                summa:summ,
                type: activePay.type,
                name: activePay.name
            }, { withCredentials: true })
            .then(res => {
                if(res.data.status == "ok"){
                    showWaitPay()
                    toStepSecondDeposit()
                    setReqDeposit(res.data.req)
                    setSummDepositSec(res.data.summ)
                }else if(res.data.status == "error min"){
                    showInfo(`Минимальная сумма для пополнения ${res.data.min_data} руб`)
                }
                
            })
        }
    }
    const chekSumm = (val)=>{
        if ((/[a-zA-Zа-яА-Я]|[^\w\s]/.test(val) == false && parseInt(val)<= 1000000) || val == '') {
            setSumm(val);
        }
    }

    const chekMobKeyboard=()=>{
        if(window.innerWidth <= 540){
            setOpenKeyboard(true)
        }
    }

    useEffect(()=>{
        if(window.innerWidth <= 540){
            setIsMobile(true)
        }
    }, [])

    return (
        <div class="modal modal__deposit">
        <div class="modal__body modal__body_w_720">
            <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
            <div class="modal__title">
                <span class="modal__title-text">Пополнение баланса</span>
                <span class="modal__title-line"></span>
            </div>
            <div class="modal__blocks">
                {
                    pays.map((pay, index) => {
                        return(
                            <div key={index} onClick={() => changePays(index)}
                            className={"modal__block modal__deposit-block "+(pay.active ? 'modal__block_active' : '')}>
                                <img className={"modal__block-img "+(pay.type == 'Карта' ? 'modal__block-img_w_140' : '')}
                                 src={require('../../assets/images/'+pay.img)} alt={pay.name}/>
                                <p class="modal__block-text">
                                    {pay.name}
                                </p>
                            </div>
                        )
                    })
                }
                
            </div>
            <label class="label modal__label">Сумма</label>
            <div class="modal__input-wrapper modal__input-wrapper-sum">
                <input class="input modal__input modal__input-sum modal__deposit-input-sum" type="text"
                    placeholder="Напишите сумму" value={summ} onChange={event => chekSumm(event.target.value)} onClick={()=>{chekMobKeyboard()}} autoComplete={isMobile ? "on" : "off"} readOnly={isMobile ? true : false}/>
            </div>
            {/* <label class="label modal__deposit-label modal__label">{activePay.type}</label>
            <input class="input modal__deposit-input" type="text" value={activePay.value} readonly=""/> */}
            <a class="btn btn_background_blue modal__btn modal__btn-deposit"
                onClick={pay}
                href="#">
                <img src={deposit} alt="deposit"/>
                Перейти к оплате
            </a>
        </div>
        {
        openKeyboard ? <Keyboard close={()=>{setOpenKeyboard(false)}} summ={summ} setSumm={(data)=>{chekSumm(data)}}/>
        : ''
        }
    </div>
    )
}

export default Deposit;