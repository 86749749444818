import up from "../../assets/images/up.svg"
import down from "../../assets/images/down.svg"
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constanta/apiConfig";
import Keyboard from "../modals/Keyboard";
import { showError, showInfo, showOpenDeal } from "../../constanta/Toastr";

function Deal({unixTimeToHMS, activeCoin, auth, setSocket, setAuth}){
    const [isOpen, setIsOpen] = useState(false);
    const [valueDeal, setValueDeal] = useState("00:01:00");
    const [summ, setSumm] = useState({summa: null, profit: 0, summ_profit: 0})
    const [openKeyboard, setOpenKeyboard] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const changeValueDeal = (data) =>{
        setIsOpen(false)
        setValueDeal(data)
    }

    const createDeal = (up_or_down) => {
        if(auth.balance < parseFloat(summ.summa)){
            showError('У вас не достаточно средств')

        }else{
            axios.post(`${BASE_URL}/api/create-deal`,{
                summ: summ.summa,
                up: up_or_down,
                time: valueDeal,
                coin_name: activeCoin.name
            }, { withCredentials: true })
            .then(res => {
                if(res.data.status == 'ok'){
                    let time = unixTimeToHMS(res.data.close_time)
                    if(res.data.up){
                        showOpenDeal(true, res.data.summa, activeCoin.name)
                    }else{
                        showOpenDeal(false, res.data.summa, activeCoin.name)
                    }
                    let new_balance = Math.round(auth.balance-res.data.summa)
                    setAuth({...auth, balance: new_balance})
                    setSocket(false)
                }else if(res.data.status == 'busy'){
                    showInfo(`Вы не можете сделать ставку на ${res.data.active_coin} пока она активна`)
                
                }else if(res.data.status == 'error betting'){
                    showInfo("Вы не можете делать ставки")
                }else{
                    showError(`Ошибка`)
                }
                
            })
        }
    }

    const chnageSummData  = (inputSumma) => {
        console.log('test')
        if (/[a-zA-Zа-яА-Я]|[^\w\s]/.test(inputSumma) || inputSumma.trim() === '') {
            setSumm({ summa: '', summ_profit: null, profit: null });
        }else if(inputSumma.length > 6){
            // let summa = '';
            // let profit = 0;
            // let summ_profit = 0;
            // setSumm({ summa, summ_profit, profit });
        } 
        else {
            let summa = parseInt(inputSumma);
            let profit = Math.round(summa * 0.92);
            let summ_profit = profit + summa;
            setSumm({ summa, summ_profit, profit });
        }
    
        
    }

    const chekMobKeyboard=()=>{
        if(window.innerWidth <= 540){
            setOpenKeyboard(true)
        }
    }


    useEffect(()=>{
        if(window.innerWidth <= 540){
            setIsMobile(true)
        }
    }, [])

    return (
        <div class="deal">
        <div class="deal__start">
            <label class="label">Время</label>
            <div class="select-deal-time-wrapper">
                <input class="input input-select deal-time" type="text" value={valueDeal} onClick={toggleDropdown} readOnly/>
                <div className={"select-deal-time "+(isOpen ? 'select-deal-time_active' : '')}>
                    <label class="label">Длительность времени</label>
                    <div class="select-deal-time__btns">
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:01:00")}
                            >60 сек</a>
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:02:00")}
                            >120 сек</a>
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:03:00")}
                            >180 сек</a>
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:04:00")}
                            >240 сек</a>
                    </div>
                </div>
            </div>
            <label class="label modal__label">Сумма</label>
            <div class="modal__input-wrapper modal__input-wrapper-sum" onClick={()=>{chekMobKeyboard()}}>
                <input class="input modal__input modal__input-sum deal-amount" type="text"
                    value={summ.summa} onChange={event => chnageSummData(event.target.value)} autoComplete={isMobile ? "on" : "off"} readOnly={isMobile ? true : false}/>
            </div>
        </div>
        <div class="deal__middle">
            <div class="deal__block">
                <div>Выплата</div>
                <div class="deal__block-wrapper">
                    <div>+92<span>%</span></div>
                    <div><span>₽</span>
                        <p class="bet-amount" style={{display: 'inline'}}>{summ.summ_profit==null ? 0 : summ.summ_profit}</p>
                    </div>
                </div>
                <div class="deal__block-line"></div>
                <div>Прибыль</div>
                <div class="deal__block-wrapper">
                    <div>+<span>₽</span>
                        <p class="bet-profit" style={{display: 'inline'}}>{summ.profit== null ? 0 : summ.profit}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="deal__end">
            <a class="btn btn_background_green deal__btn" onClick={()=>createDeal(true)}>
                Выше
                <img src={up} alt="up"/>
            </a>
            <a class="btn btn_background_red deal__btn"  onClick={()=>createDeal(false)}>
                Ниже
                <img src={down} alt="down"/>
            </a>
        </div>
        {
        openKeyboard ? <Keyboard close={()=>{setOpenKeyboard(false)}} summ={summ.summa} setSumm={(data)=>{chnageSummData(data)}}/>
        : ''
        }
    </div>
    )
}

export default Deal;