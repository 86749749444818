import Header from "../components/Header";
import HistoryItems from "../components/HistoryItems";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context";
import axios from "axios";
import { BASE_URL } from "../constanta/apiConfig";
import HistoryItemsMob from "../components/HistoryItemsMob";

function History(){
    const {auth, setAuth} = useContext(AuthContext)
    const [historys, setHistory] = useState(
        []
    )
    useEffect(() => {
        setAuth({...auth, loading: true});
        axios.post(`${BASE_URL}/api/get-history`,{}, { withCredentials: true })
        .then(res => {
            setHistory(res.data)
            setAuth({...auth, loading: false});
        })
      }, []);
    return (
        <div>
            <div class="wrapper" style={{'minHeight': 100+'vh'}}>
                <Header/>
                <main class="main">

                    <section class="history">
                        <h1 class="title">Отзывы</h1>
                        <h2 class="subtitle">Отзывы пользователей сервиса</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Тип</th>
                                    <th>Дата</th>
                                    <th>Способ</th>
                                    <th>Сумма</th>
                                    <th>Статус</th>
                                </tr>
                            </thead>
                            <tbody class="deckstop">
                                {
                                    historys.length != 0 ?
                                    historys.map((history, index)=>{
                                        return(
                                            <HistoryItems
                                            key={index}
                                            history={history}
                                            />
                                        )
                                    })
                                    :''
                                }
                            </tbody>
                            <tbody class="mobile">
                                {
                                    historys.length != 0 ?
                                    historys.map((history, index)=>{
                                        return(
                                            <HistoryItemsMob
                                            key={index}
                                            history={history}
                                            />
                                        )
                                    })
                                    :''
                                }
                            </tbody>
                        </table>
                    </section>
                </main>

            </div>
        </div>
    )
}

export default History;