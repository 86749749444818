import { useEffect, useState } from "react";

function InfoDealItem({name, procent, time, summ, profit, summ_profit}){
    const [formattedTime, setFormattedTime] = useState('');

    useEffect(()=>{
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const secs = time % 60;
    
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = secs.toString().padStart(2, '0');
    
        setFormattedTime(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`)
    }, [time])

    return (
        <div class="information_d">
            <div class="column__deals mb-8">
                <span class="text_deals">{name}</span>
                <div class="summ_sign_balance align-i-start">
                    <span class="text_deals">+{procent}</span>
                    <span class="sign_balance">%</span>
                </div>
                <span class="text_deals">{formattedTime}</span>
            </div>
            <div class="column__deals">
                <div class="summ_sign_balance">
                    <span className={"sign_info_summ "+(profit <= 0 ? "c-red" : 'c-green')}>₽</span>
                    <span class="text_deals">{summ}</span>
                </div>
                <div class="summ_sign_balance">
                    <span className={"sign_info_summ "+(profit <= 0 ? "c-red" : 'c-green')}>{profit <= 0 ? '-' : '+'}₽</span>
                    <span class="text_deals">{profit <= 0 ?summ_profit*-1 :summ_profit}</span>
                    
                </div>
                <div class="summ_sign_balance">
                    <span className={"sign_info_summ "+(profit <= 0 ? "c-red" : 'c-green')}>{profit <= 0 ? '-' : '+'}₽</span>
                    <span class="text_deals">{profit <= 0 ? profit*-1 : profit}</span>
                </div>
            </div>
        </div>
    )
}

export default InfoDealItem;