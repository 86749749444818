

import React, { useState, useRef, useEffect } from 'react';

function SelectCoin({coins, setCoins, activeCoin, timeChar, openMobDeal}){
    const [isOpen, setIsOpen] = useState(false);

    
    const clickCoin=(clickedIndex)=>{
        setCoins(coins.map((coin, index) => {
            return index === clickedIndex
                ? { ...coin, active: true }
                : { ...coin, active: false };
        }));
        toggleDropdown()
    }

    



    const contentRef = useRef(null)
    const dropwnRef = useRef(null)

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (!contentRef.current.contains(event.target) && !dropwnRef.current.contains(event.target)) {
            setIsOpen(false)
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    return (
        <div class="select-coin">
                          <div class="select-coin__header" onClick={toggleDropdown} ref={dropwnRef}>
                              <span>
                                  <img src={activeCoin.img} alt="coin"/>
                                  <span>
                                      {activeCoin.name} </span>
                              </span>
                              <svg className={"header__balance-arrow "+(isOpen ? 'header__balance-arrow_active' : '')}
                              xmlns="http://www.w3.org/2000/svg" width="14" height="8"
                                  viewBox="0 0 14 8" fill="none">
                                  <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1"
                                      stroke="white" stroke-width="2" stroke-linecap="round"></path>
                              </svg>
                          </div>
                          <div className={"select-coin__body "+(isOpen ? 'select-coin__body_active' : '')} ref={contentRef}>
                              {
                                coins.map((coin, index)=>{
                                    return (
                                        <a key={index} class="select-coin__item" onClick={()=>clickCoin(index)}>
                                            <img src={coin.img} alt="coin"/>
                                            <span>
                                                {coin.name} </span>
                                        </a>
                                    )
                                })
                              }
                          </div>
                          <div class="schedule_time">
                              <span class="text_time_shedule">{timeChar}</span>
                              <span class="text_time_shedule">UTC+3</span>
                          </div>
                          <div class="deal-btn-mob" onClick={openMobDeal}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 13.8333C10.9452 13.8333 13.333 11.4455 13.333 8.50001C13.333 5.55449 10.9452 3.16668 7.99967 3.16668C5.05416 3.16668 2.66634 5.55449 2.66634 8.50001C2.66634 11.4455 5.05416 13.8333 7.99967 13.8333ZM7.99967 15.1667C11.6816 15.1667 14.6663 12.1819 14.6663 8.50001C14.6663 4.81811 11.6816 1.83334 7.99967 1.83334C4.31778 1.83334 1.33301 4.81811 1.33301 8.50001C1.33301 12.1819 4.31778 15.1667 7.99967 15.1667Z" fill="white" style={{
      mixBlendMode: 'overlay'
    }}/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 13.8333C10.9452 13.8333 13.333 11.4455 13.333 8.50001C13.333 5.55449 10.9452 3.16668 7.99967 3.16668C5.05416 3.16668 2.66634 5.55449 2.66634 8.50001C2.66634 11.4455 5.05416 13.8333 7.99967 13.8333ZM7.99967 15.1667C11.6816 15.1667 14.6663 12.1819 14.6663 8.50001C14.6663 4.81811 11.6816 1.83334 7.99967 1.83334C4.31778 1.83334 1.33301 4.81811 1.33301 8.50001C1.33301 12.1819 4.31778 15.1667 7.99967 15.1667Z" fill="white" fill-opacity="0.2"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 4.5C8.36786 4.5 8.66634 4.79848 8.66634 5.16667V8.22386L10.1377 9.69526C10.3981 9.95561 10.3981 10.3777 10.1377 10.6381C9.8774 10.8984 9.45529 10.8984 9.19494 10.6381L7.52827 8.9714C7.40325 8.84638 7.33301 8.67681 7.33301 8.5V5.16667C7.33301 4.79848 7.63148 4.5 7.99967 4.5Z" fill="white" style={{
      mixBlendMode: 'overlay'
    }}/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99967 4.5C8.36786 4.5 8.66634 4.79848 8.66634 5.16667V8.22386L10.1377 9.69526C10.3981 9.95561 10.3981 10.3777 10.1377 10.6381C9.8774 10.8984 9.45529 10.8984 9.19494 10.6381L7.52827 8.9714C7.40325 8.84638 7.33301 8.67681 7.33301 8.5V5.16667C7.33301 4.79848 7.63148 4.5 7.99967 4.5Z" fill="white" fill-opacity="0.2"/>
                            </svg>

                            <span>Сделки</span>
                          </div>
                      </div>
    )
}

export default SelectCoin;