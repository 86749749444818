import cross from "../../assets/images/cross.svg"
import cards from "../../assets/images/cards.svg"
import qiwi from "../../assets/images/qiwi.svg"
import bitcoin from "../../assets/images/bitcoin.svg"
import withdrawal from "../../assets/images/withdrawal.svg"
import { useState, useEffect, useRef } from "react"
import axios from "axios"
import { BASE_URL } from "../../constanta/apiConfig";
import Keyboard from "./Keyboard"
import { showError, showWaitPay } from "../../constanta/Toastr"

function Withdrawal({close, setAuth, auth}){
    const [pays, setPays] = useState([
        {active: true, img: cards, name: "Bank Card (RUB)", value: '', type: 'Карта'},
        {active: false, img: qiwi, name: "QIWI", value: '', type: 'QIWI'},
        {active: false, img: bitcoin, name: "Bitcoin", value: '', type: 'Bitcoin'}
    ])

    const [summ, setSumm] = useState('')
    const [openKeyboard, setOpenKeyboard] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const changePays = (clickedIndex) => {
        setPays(pays.map((pay, index) => {
            return index === clickedIndex
                ? { ...pay, active: true }
                : { ...pay, active: false };
        }));
    }

    const activePay = pays.find(pay => pay.active);

    const withdrawalFounds=()=>{
        if(summ.length > 6){
            // showError('Максимальная сума для вывода 100000')
        }else if(summ > auth.balance){
            showError('Недостаточно средств на вывод')
        }
        else if(activePay.value == ''){
            //showError('Уведите реквизиты')
        }else if(summ.length == 0){
            //showError('Уведите суму')
        }
        else{
            close()
            axios.post(`${BASE_URL}/api/create-withdrawal`,{
                type: activePay.type,
                requisites: activePay.value,
                summ: summ,
                name: activePay.name
            }, { withCredentials: true })
            .then(res => {
                if(res.data.status == "ok"){
                    showWaitPay()
                    let new_balance = Math.round(auth.balance-summ)
                    setAuth({...auth, balance: new_balance})
                }else if(res.data.status == "error"){
                    showError(res.data.message)
                }
                
            })
        }
    }

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setPays(pays.map(pay => 
            pay.active ? {...pay, value: newValue} : pay
        ));
    };

    const chekSumm = (val) => {
        if ((/[a-zA-Zа-яА-Я]|[^\w\s]/.test(val) == false && parseInt(val)<= 1000000) || val == '') {
            setSumm(val);
        }
    }

    const chekMobKeyboard=()=>{
        if(window.innerWidth <= 540){
            setOpenKeyboard(true)
        }
    }

    useEffect(()=>{
        if(window.innerWidth <= 540){
            setIsMobile(true)
        }
    }, [])


    return (
        <div class="modal modal__withdrawal">
        <div class="modal__body modal__body_w_720">
            <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
            <div class="modal__title">
                <span class="modal__title-text">Вывод</span>
                <span class="modal__title-line"></span>
            </div>
            <div class="modal__blocks">
            {
                    pays.map((pay, index) => {
                        return(
                            <div key={index} onClick={() => changePays(index)}
                            className={"modal__block modal__deposit-block "+(pay.active ? 'modal__block_active' : '')}>
                                <img className={"modal__block-img "+(pay.type == 'Карта' ? 'modal__block-img_w_140' : '')}
                                 src={pay.img} alt={pay.name}/>
                                <p class="modal__block-text">
                                    {pay.name}
                                </p>
                            </div>
                        )
                    })
                }
                
            </div>
            <label class="label modal__label">Сумма</label>
            <div class="modal__input-wrapper modal__input-wrapper-sum">
                <input class="input modal__input modal__input-sum modal__withdrawal-input-sum" type="text"
                    placeholder="Напишите сумму" value={summ} onChange={event => chekSumm(event.target.value)} onClick={()=>chekMobKeyboard()} autoComplete={isMobile ? "on" : "off"} readOnly={isMobile ? true : false}/>
            </div>
            <label class="label modal__withdrawal-label modal__label">{activePay.type}</label>
            <input class="input modal__withdrawal-input modal__withdrawal-payment-details" type="text"
                placeholder="Укажите реквизиты." value={activePay.value} onChange={handleInputChange}
                />
            <a class="btn btn_background_blue modal__btn modal__btn-withdrawal"
                onClick={withdrawalFounds}
                href="#">
                <img src={withdrawal} alt="withdrawal"/>
                Создать запрос на вывод
            </a>
        </div>
        {
        openKeyboard ? <Keyboard close={()=>{setOpenKeyboard(false)}} summ={summ} setSumm={(data)=>{chekSumm(data)}}/>
        : ''
        }
    </div>
    )
}

export default Withdrawal;