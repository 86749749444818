
import Header from "../components/Header";
import FaqBtns from "../components/faq/FaqBtns";
import FaqText from "../components/faq/FaqText";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context";

function Faq(){
    const {auth, setAuth} = useContext(AuthContext)
    const [infoBtns, setInfoBtns] = useState([
        {
            title: 'Основы', active: true, info: [
            {subtitle: '1. Интерфейс', active: true, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]},
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]},
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]}
            ],
        },
        {
            title: 'Торговля', active: false, info: [
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]},
            {subtitle: '1. Интерфейс', active: false, text: [{description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]},
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]}
            ],
        },
        {
            title: 'Баланс', active: false, info: [
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]},
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]},
            {subtitle: '1. Интерфейс', active: false, text: [{title: 'Руководства пользователя', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel. Sit leo turpis cursus non ipsum nibh. Id consequat ultrices pretium ut euismod erat facilisi. Accumsan rhoncus neque platea netus gravida sem tellus dictum ultrices. Et eleifend libero ultricies arcu iaculis porta. Commodo iaculis elit fermentum cum semper. Odio posuere egestas id amet faucibus mauris vehicula. Id dui neque odio convallis. Mi commodo adipiscing mattis magna dignissim odio.'}]}
            ],
        },
    ])

    const changeActiveBtn=(clickIndexBtn)=>{
        setInfoBtns(infoBtns.map((btn, index) => {
            if(index == clickIndexBtn){
                return {...btn, active: !btn.active}
            }else{
                return {...btn, active: btn.active}
            }
        }));
    }


    const changeSubBtn=(indexBtn, indexSubBtn)=>{
        console.log('test')
        setInfoBtns(infoBtns.map((btn, btnIndex) => {
            if (btnIndex === indexBtn) {
                return {
                    ...btn,
                    info: btn.info.map((subInfo, subInfoIndex) => {
                        return {
                            ...subInfo,
                            active: subInfoIndex === indexSubBtn
                        };
                    })
                };
            } else {
                return {
                    ...btn,
                    info: btn.info.map(subInfo => ({
                        ...subInfo,
                        active: false
                    }))
                };
            }
        }));
    }
    useEffect(() => {
        setAuth({...auth, loading: true});
        const timer = setTimeout(() => setAuth({...auth, loading: false}), 500);
        return () => clearTimeout(timer);
      }, []);
    return (
        <div>
            <div class="wrapper" style={{'minHeight': 100+'vh'}}>
                <Header/>
                <main class="main">

                    <section class="faq">
                        <div class="container faq__container">
                            <h1 class="title">
                                Руководства пользователя
                            </h1>
                            <h2 class="subtitle">
                                Руководства пользователя для быстрого старта
                            </h2>
                            <div class="faq__wrapper">
                                <div class="faq__start">
                                {
                                    infoBtns.map((info, index) => {
                                        return(
                                            <FaqBtns key={index} 
                                            infoBtn={info}
                                            clickBtn={changeActiveBtn}
                                            index={index}
                                            change_sub_btn={changeSubBtn}
                                            />
                                        )
                                    })
                                }
                                </div>
                                
                                {
                                    infoBtns.map((btn, index) => {
                                        return btn.info.map((info, infoIndex) => {
                                            if (info.active) {
                                                return <FaqText key={index + '-' + infoIndex} texts={info.text} />;
                                            }
                                            return null;
                                        });
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </main>

            </div>
        </div>
    )
}

export default Faq;