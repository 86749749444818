function Keyboard({summ, setSumm, close}){


    const changeSumm=(number)=>{
        if(summ == null){
            summ = ''
        }
        setSumm(summ.toString() + number.toString())
    }

    const clearSumm=()=>{
        let new_summ = summ.toString().slice(0, -1);
        setSumm(new_summ)
    }

    return(<div class="keyboard">
        <label class="label modal__label">Сумма</label>
        <div class="modal__input-wrapper modal__input-wrapper-sum input-keyboard-wrapper">
            <input class="input modal__input modal__input-sum deal-amount input-keyboard" type="text" readOnly value={summ}/>
        </div>
        <div class="btns-keyboard">
            <div class="btn-keyboard" onClick={()=>{changeSumm(1)}}>
                <span>1</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(2)}}>
                <span>2</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(3)}}>
                <span>3</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(4)}}>
                <span>4</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(5)}}>
                <span>5</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(6)}}>
                <span>6</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(7)}}>
                <span>7</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(8)}}>
                <span>8</span>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(9)}}>
                <span>9</span>
            </div>
            <div class="btn-keyboard" style={{'background': 'rgba(45, 97, 235, 1)'}} onClick={()=>{close() }}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 7.04184C2.65912 4.84504 4.34504 3.15912 6.54184 2.86899C8.05208 2.66953 9.94127 2.5 12 2.5C14.0587 2.5 15.9479 2.66953 17.4582 2.86899C19.655 3.15912 21.3409 4.84504 21.631 7.04184C21.8305 8.55208 22 10.4413 22 12.5C22 14.5587 21.8305 16.4479 21.631 17.9582C21.3409 20.155 19.655 21.8409 17.4582 22.131C15.9479 22.3305 14.0587 22.5 12 22.5C9.94127 22.5 8.05208 22.3305 6.54184 22.131C4.34504 21.8409 2.65912 20.155 2.36899 17.9582C2.16953 16.4479 2 14.5587 2 12.5C2 10.4413 2.16953 8.55208 2.36899 7.04184Z" fill="white"/>
                    <path d="M10.8512 17.5C11.2123 17.5 11.5186 17.323 11.7264 16.9801L16.7264 9.02655C16.8577 8.81638 17 8.56194 17 8.31858C17 7.8208 16.5623 7.5 16.1028 7.5C15.8184 7.5 15.5449 7.67699 15.3479 7.99779L10.7964 15.3651L8.64113 12.5443C8.38949 12.1903 8.13785 12.1017 7.84245 12.1017C7.36105 12.1017 7 12.4889 7 12.9756C7 13.219 7.08753 13.4513 7.2407 13.6615L9.91025 16.9801C10.1947 17.3451 10.4792 17.5 10.8512 17.5Z" fill="white"/>
                </svg>
            </div>
            <div class="btn-keyboard" onClick={()=>{changeSumm(0)}}>
                <span>0</span>
            </div>
            <div class="btn-keyboard" onClick={clearSumm}>
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7578 14.6641L8 14.6562C6.96094 14.6562 6.19531 14.3594 5.49219 13.5938L1.0625 8.92188C0.492188 8.3125 0.273438 7.85156 0.273438 7.35938C0.273438 6.86719 0.492188 6.41406 1.0625 5.79688L5.48438 1.10156C6.1875 0.335938 6.95312 0.078125 8 0.078125H14.7578C16.4453 0.078125 17.3203 0.945312 17.3203 2.61719V12.1875C17.3203 13.8594 16.4453 14.6641 14.7578 14.6641ZM14.6641 13.1328C15.3906 13.1328 15.7891 12.75 15.7891 11.9922V2.74219C15.7891 1.98438 15.3906 1.60938 14.6641 1.60938H7.92969C7.39062 1.60938 7.03125 1.71094 6.64844 2.10938L2.25781 6.77344C2.03125 7.01562 1.95312 7.17969 1.95312 7.35938C1.95312 7.53906 2.02344 7.71094 2.25781 7.95312L6.65625 12.5938C7.03906 12.9922 7.39062 13.1328 7.9375 13.1328H14.6641ZM7.67188 10.6484C7.28125 10.6484 6.96875 10.3359 6.96875 9.9375C6.96875 9.75 7.04688 9.57812 7.17969 9.45312L9.25 7.375L7.17969 5.30469C7.04688 5.17188 6.96875 5 6.96875 4.8125C6.96875 4.42188 7.28125 4.11719 7.67188 4.11719C7.875 4.11719 8.03125 4.17969 8.17188 4.32031L10.2422 6.39062L12.3281 4.32031C12.4766 4.17188 12.6328 4.10156 12.8203 4.10156C13.2188 4.10156 13.5312 4.41406 13.5312 4.80469C13.5312 4.99219 13.4609 5.15625 13.3125 5.29688L11.2422 7.375L13.3125 9.44531C13.4453 9.57812 13.5234 9.74219 13.5234 9.9375C13.5234 10.3359 13.2109 10.6484 12.8203 10.6484C12.6094 10.6484 12.4375 10.5703 12.3047 10.4375L10.2422 8.375L8.17969 10.4375C8.04688 10.5781 7.875 10.6484 7.67188 10.6484Z" fill="white"/>
                </svg>
            </div>
        </div>
    </div>)
}

export default Keyboard;