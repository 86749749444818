
import Header from "../components/Header";
import Leftsidebar from "../components/index/LeftSidebar";
import SelectCoin from "../components/index/SelectCoin";
import Chart from "../components/index/Chart";
import Deal from "../components/index/Deal";
import RightSidebar from "../components/index/RightSidebar";
import Finance from "../components/modals/Finance";
import Deposit from "../components/modals/Deposit";
import Withdrawal from "../components/modals/Withdrawal";
import Help from "../components/modals/Help";
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import bitcoin from "../assets/images/1.png"
import Ethereum from "../assets/images/1027.png"
import Ripple from "../assets/images/52.png"
import Solana from "../assets/images/5426.png"
import Tron from "../assets/images/1958.png"
import io from 'socket.io-client';
import { AuthContext } from "../context";
import { BASE_URL } from "../constanta/apiConfig";
import DepositSecond from "../components/modals/DepositSecond";
import { showDealClose, showYouWin, showYouLose, showOpenDeal } from "../constanta/Toastr";

function Index() {
  const [modals, setModals] = useState({finance: false, deposit: false, withdraw: false, help: false, depositSec: false})
  const [pays, setPays] = useState()
  const [openDeals, setOpenDeals] = useState([])
  const [closeDeals, setCloseDeals] = useState([])
  const [coins, setCoins] = useState([
      {name: 'Bitcoin', img: bitcoin, active: true, id: 1},
      {name: 'Ethereum', img: Ethereum, active: false, id: 2},
      {name: 'Ripple', img: Ripple, active: false, id: 3},
      {name: 'Solana', img: Solana, active: false, id: 4},
  ])
  const [charData, setCharData] = useState([])
  const [timeChar, setTimeChar] = useState('')
  const {auth, setAuth} = useContext(AuthContext)
  const [valOpenDeal, setValOpenDeal] = useState({time: 0, value: 0})
  const [mobDeals, setMobDeals] = useState(false)
  
  const activeCoin = coins.find(coin => coin.active)
  const [socketActive, setSocket] = useState(true)
  const [summDeposit, setSummDeposit] = useState('')
  const [reqDepositSec, setReqDepositSec] = useState('')
  const [summDepositSec, setSummDepositSec] = useState('')
  const [timer, setTimer] = useState({minutes: 15, seconds: 0})
  

  const toggleModal = (modalsState) => {
    setModals(prevModals => ({...prevModals, ...modalsState}));
  }

  const addNewChar = (priceData)=>{
    setCharData(prevCharData => {
      if (!prevCharData || prevCharData.length <= 1) {
        return [{time: priceData.time, value: priceData.value}];
      }
  
      const newCharData = prevCharData.slice(1);
      return [...newCharData, {time: priceData.time, value: priceData.value}];
    });
    setTimeChar(unixTimeToHMS(priceData.time))
  }

  function openMobDeal(){
    setMobDeals(!mobDeals)
  }

  useEffect(()=>{
    setAuth({...auth, loading: true})
    axios.post(`${BASE_URL}/api/get-char`,{coin: activeCoin.name}, { withCredentials: true })
    .then(res => {
        setSocket(res.data.user.channel)
        setCharData(res.data.char)
        setAuth({...auth, loading: false})
        if(res.data.start_deal){
          setValOpenDeal({time: res.data.start_deal.time, value: res.data.start_deal.value})
        }
    })
    const socket = io(`${BASE_URL}`);
    socket.on('price'+activeCoin.name, (priceData) => {
      if(socketActive){
        addNewChar(priceData)
      }
    });
    const socketDeal = io(`${BASE_URL}`);
    socketDeal.on(auth.login+activeCoin.name, (priceData) => {
      if(!socketActive){
        if(priceData.close){
          setSocket(true)
          
        }else{
          addNewChar(priceData)
        }
        
      }
    });



    return () => {
      socket.off('price'+activeCoin.name);
      socket.disconnect();
      socketDeal.off(auth.login+activeCoin.name);
      socketDeal.disconnect();
  };
  }, [activeCoin, socketActive])
  useEffect(() => {

    axios.post(`${BASE_URL}/api/get-pays`,{}, { withCredentials: true })
    .then(res => {
        setPays(res.data)
        chekDepositStart(res.data);
    })
    axios.post(`${BASE_URL}/api/get-deals`,{}, { withCredentials: true })
    .then(res => {
        setCloseDeals(res.data.close_deals)
        setOpenDeals(res.data.open_deals)
        setAuth({...auth, loading: false})
    })

    

    const socketDealsActive = io(`${BASE_URL}`);
    socketDealsActive.on(auth.login+'deals_user', (dealsData) => {
      console.log(auth.balance)
      if(dealsData.close){
        closeDeal(dealsData)
      }else{
        setOpenDeals(prevOpenDeals => {
          const dealExists = prevOpenDeals.some(deal => deal.id === dealsData.id);

          if (!dealExists) {
            return [...prevOpenDeals, {name: dealsData.coin, procent: dealsData.procent,
            time: dealsData.time, summ: dealsData.summ,
            profit: dealsData.profit, summ_profit: dealsData.summ_profit, id: dealsData.id}];
          } else {
            return prevOpenDeals.map(deal => 
              deal.id === dealsData.id ? { ...deal, ...dealsData } : deal
            );
          }
        });
        if(dealsData.open_deal){
          setValOpenDeal({time: dealsData.open_deal.time, value: dealsData.open_deal.value})
        }
      }
      
    });
    return () => {
      socketDealsActive.off(auth.login+'deals_user');
      socketDealsActive.disconnect();
  };
  
  }, []);



  const closeDeal = (dealsData)=>{
    setCloseDeals(prevCloseDeals => [...prevCloseDeals, {name: dealsData.name, procent: dealsData.procent,
      time: dealsData.time, summ: dealsData.summ,
      profit: dealsData.profit, summ_profit: dealsData.summ_profit, id: dealsData.id}])
    setOpenDeals (prevOpenDeals =>{
      return prevOpenDeals.filter(deal => deal.id !== dealsData.id);
    })

    setValOpenDeal(false)

    if(dealsData.win){
      setAuth({...auth, balance: dealsData.balance})
      showDealClose('+', dealsData.summ_profit, dealsData.profit, dealsData.name, dealsData.up_user)
      showYouWin(dealsData.summ_profit)
    }else{
      showDealClose('-', 0, 0, dealsData.name, dealsData.up_user)
      showYouLose(dealsData.summ_profit)
    }
  }

  const toStepSecondDeposit = () =>{
    toggleModal({deposit: false, depositSec: true})
  }

  const unixTimeToHMS=(unixTime)=> {
    var date = new Date(unixTime * 1000); 

    var hours = date.getHours() - 3;
    if (hours < 0) {
      hours += 24;
    }
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();

    return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  }

  const chekDepositStart=(data)=>{
    axios.post(`${BASE_URL}/api/get-deposit-step`, {}, { withCredentials: true })
    .then(res=>{
      if (res.data.step_sec == true){
        setPays(data.map((pay, index) => {
            return pay.name === res.data.type_active
                ? { ...pay, active: true }
                : { ...pay, active: false };
        }));
        setSummDeposit(res.data.summ)
        setReqDepositSec(res.data.req)
        setSummDepositSec(res.data.summ)
        toggleModal({depositSec: true, finance: false})

      }
    })
  }

  const openDeposit=()=>{
    axios.post(`${BASE_URL}/api/get-deposit-step`, {}, { withCredentials: true })
    .then(res=>{
      if (res.data.step_sec == false){
        toggleModal({deposit: true, finance: false})
      }else{
        setPays(pays.map((pay, index) => {
            return pay.name === res.data.type_active
                ? { ...pay, active: true }
                : { ...pay, active: false };
        }));
        setReqDepositSec(res.data.req)
        setSummDeposit(res.data.summ)
        setSummDepositSec(res.data.summ)
        toggleModal({depositSec: true, finance: false})

      }
    })
  }

  
  return (
    <div>

      <div class="wrapper">
          <div class="left-sidebar-grid">
              <Header/>
              <Leftsidebar open_help={() => toggleModal({help: true})} open_finance={() => toggleModal({finance:true})}/>
              <main class="main main-index" style={{
                height: '100%',
              }}>
                  <div class="container" style={{overflow: 'hidden'}}>
                      <SelectCoin coins={coins} setCoins={setCoins} activeCoin={activeCoin} timeChar={timeChar} openMobDeal={openMobDeal}/>
                      <Chart charData={charData} valOpenDeal={valOpenDeal} socketActive={socketActive}/>
                      <Deal unixTimeToHMS={unixTimeToHMS} activeCoin={activeCoin} 
                      auth={auth}
                      setSocket={setSocket}
                      setAuth={setAuth}/>
                  </div>
                  <div class="filler_shedule">
                      <div class="elipse index_elipse2">

                      </div>
                      <div class="elipse index_elipse4">

                      </div>
                      <div class="elipse index_elipse3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="175" height="110" viewBox="0 0 175 110" fill="none">
                              <circle cx="87.5" cy="87.5" r="87.5" fill="#2D61EB"></circle>
                          </svg>
                      </div>
                      <div class="elipse index_elipse5">

                      </div>
                      <div class="elipse index_elipse6">

                      </div>
                  </div>
              </main>
              <RightSidebar close_deals={closeDeals} open_deals={openDeals} mobDeals={mobDeals} openMobDeal={openMobDeal}/>
              {modals.withdraw ? <Withdrawal 
              close={() => toggleModal({withdraw: false})} setAuth={setAuth} auth={auth}/> : ''}
              {modals.deposit ? <Deposit 
              toStepSecondDeposit={toStepSecondDeposit} pays={pays} setPays={setPays} close={()=>toggleModal({deposit: false})} summ={summDeposit} setSumm={setSummDeposit} setReqDeposit={setReqDepositSec} setSummDepositSec={setSummDepositSec}/> 
              : ''}
              {modals.finance ? <Finance 
               close={() => toggleModal({finance: false})}
               open_withdrawal={() => toggleModal({withdraw: true, finance: false})}
               open_deposit={() => openDeposit()}
               /> : ''}
              {modals.help ? <Help close={() => toggleModal({help: false})}/> : ''}
              {
                modals.depositSec ? <DepositSecond close={()=>toggleModal({depositSec: false})} pays={pays} summ={summDeposit} login={auth.login}
                timer={timer}
                setTimer={setTimer}
                req={reqDepositSec}
                summDepositSec={summDepositSec}
                />
                :
                ''
              }
              
          </div>
      </div>
    </div>
  );
}

export default Index;
