function FaqText({texts}){
    return (
        <div class="faq__end">
            <h3 class="title faq__title_big">
                Интерфейс
            </h3>
            <div class="faq__chapter faq__chapter-interface">
                {
                    texts.map((text, index)=>{
                        return (
                            <>
                                {text.title &&
                                    <h4 className="title faq__title">
                                        {text.title}
                                    </h4>
                                }
                                {text.description &&
                                    <h5 className="subtitle faq__subtitle">
                                        {text.description}
                                    </h5>
                                }
                            </>
                        );
                    })
                }
                
            </div>
        </div>
    )
}

export default FaqText;