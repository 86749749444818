import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';

function Chart({charData, valOpenDeal, socketActive}){
    const chartContainerRef = useRef(null);
    const [heightChar, setHeightChar] = useState(400)
    const [minusWidthChar, setMinusWidthChar] = useState(40)
    const rightOffset = 10
    const markers = []
    

    useEffect(() => {
        if(window.innerHeight <= 726){
            setHeightChar(350)
        }else{
            setHeightChar(400)
        }
        if(window.innerWidth <=320){
            setMinusWidthChar(0)
        } else if(window.innerWidth <=540){
            setMinusWidthChar(0)
            if (window.innerHeight > 800){
                setHeightChar(400)
            }
            else if(window.innerHeight <= 660){
                setHeightChar(250)
            }else if(window.innerHeight <=670){
                setHeightChar(275)
            }
            else if(window.innerHeight <= 710){
                setHeightChar(300)
            }else if(window.innerHeight <=720){
                setHeightChar(325)
            }
            else{
                setHeightChar(350)
            }
            
        }

        if (chartContainerRef.current) {
            const chartOptions = {
                width: chartContainerRef.current.getBoundingClientRect().width - minusWidthChar,
                height: heightChar,
                layout: {
                    textColor: 'rgba(255, 255, 255, 0.30)',
                    background: {
                        type: 'solid',
                        color: 'transparent'
                    },
                },
                rightPriceScale: {
                    borderVisible: false, 
                },
                grid: {
                    vertLines: {
                        visible: false
                    },
                    horzLines: {
                        color: "transparent"
                    }
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: true,
                    borderVisible: false,
                    rightOffset: rightOffset
                },
                handleScroll: false,
                handleScale: false,
            };

            const chart = createChart(chartContainerRef.current, chartOptions);

            const areaSeries = chart.addAreaSeries({
                topColor: '#20879e78',
                bottomColor: '#20879e00',
                lineColor: '#1c738c',
                lineWidth: 3,
            });

            const updateChartDataWithAnimation = () => {
                areaSeries.setData(charData);
    
                chart.applyOptions({
                    animation: {
                        duration: 1000,
                        type: 'ease-in-out'
                    }
                });
            };
    
            updateChartDataWithAnimation();
    
            
            

            

            if (!socketActive && valOpenDeal) {
                let lastCharDataTime = charData[charData.length - 1].time;
                let dealTime = valOpenDeal.time;
            
                const executeCode = () => {
                    
                    
            
                    const priceLineSeries = chart.addLineSeries({
                        color: 'red',
                        lineWidth: 2,
                        lineStyle: LineStyle.Dotted,
                        priceLineVisible: false,
                        lastValueVisible: true,
                    });
                    
                    priceLineSeries.setData([
                        { time: dealTime, value: valOpenDeal.value },
                        { time: charData[charData.length - 1].time, value: valOpenDeal.value }
                    ]);

                    markers.push({
                        time: dealTime,
                        position: 'aboveBar',
                        color: 'red',
                        shape: 'arrowDown',
                        text: 'Точка открытия',
                        font: 'bold 12px Arial' 
                    });
                };
            
                if (dealTime === lastCharDataTime) {
                    setTimeout(executeCode, 5000); 
                } else {
                    executeCode();
                }
            }
            if(charData[charData.length - 1]){
                const lastDataPoint = charData[charData.length - 1];
                markers.push({
                    time: lastDataPoint.time,
                    position: 'inBar',
                    color: '#1c738c',
                    shape: 'circle',
                    size: 1,
                });
            }    

            areaSeries.setMarkers(markers);

            const handleResize = () => {
                if (chartContainerRef.current) {
                    chartOptions.width = chartContainerRef.current.getBoundingClientRect().width - 40;
                    chart.applyOptions(chartOptions);
                }
            };


            return () => {
                chart.remove();
            };
        }
    }, [charData, socketActive]);

    return (
        <div id="chart" class="chart" ref={chartContainerRef}>

        </div>
    )
}

export default Chart;