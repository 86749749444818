

function Loader(){
    return (
    <div class="preloader">
        <div class="preloader__icon"></div>
    </div>
    )
}

export default Loader;