import start from "../assets/images/star.svg";

function ReviewsItems({review}){
    return (
        <div class="reviews__block">
            <div class="reviews__block-top">
                <div class="reviews__block-name">
                    {review.name}
                </div>
                <div class="reviews__block-star">
                    <img src={start} alt="png"/>
                    <span>{review.star}</span>
                </div>
                <div class="reviews__block-line"></div>
                <div class="reviews__block-date">
                    {review.data}
                </div>
            </div>
            <div class="reviews__block-bottom">
                <p class="reviews__block-review">
                    {review.description}
                </p>
            </div>
        </div>
    )
}

export default ReviewsItems;