function FaqBtns({infoBtn, clickBtn, index, change_sub_btn}){
    return (
        <div class="faq__block">
            <div class="faq__block-wrapper" onClick={()=>clickBtn(index)}>
                <div class="faq__block-header">
                    {infoBtn.title}
                </div>
                <svg className={"faq__block-arrow "+(infoBtn.active ? 'faq__block-arrow_active' : '')} xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                    <path d="M13 1.5L7.70711 6.79289C7.31658 7.18342 6.68342 7.18342 6.29289 6.79289L1 1.5" stroke="white" stroke-width="2" stroke-linecap="round"></path>
                </svg>
            </div>
            <div className={"faq__block-body "+(infoBtn.active ? 'faq__block-body_active' : '')}>
                {
                    infoBtn.info.map((subBtn, indexSub)=>{
                        return(
                            <a key={indexSub} className={"faq__block-link "+(subBtn.active ? 'faq__block-link_active' : '')}
                            onClick={()=>change_sub_btn(index, indexSub)}
                            >{subBtn.subtitle}</a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FaqBtns;