import Header from "../components/Header";
import ReviewsItems from "../components/ReviewsItems";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context";

function Reviews(){
    const {auth, setAuth} = useContext(AuthContext)
    useEffect(() => {
        setAuth({...auth, loading: true});
        const timer = setTimeout(() => setAuth({...auth, loading: false}), 500);
        return () => clearTimeout(timer);
      }, []);

    const [reviews, setReviews] = useState([
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 4, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 4, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        {name: 'Макс', star: 5, data: '1 октября 2023 г. в 06:48', description: 'Lorem ipsum dolor sit amet consectetur. Fermentum sem pretium tincidunt et. Egestas quis orci nulla blandit vel.'},
        
    ])

    return (
        <div class="wrapper" style={{'minHeight': 100+'vh'}}>
            <Header/>
            <main class="main">

                <section class="reviews">
                    <div class="container reviews__container">
                        <h1 class="title">
                            Отзывы
                        </h1>
                        <h2 class="subtitle">
                            Отзывы пользователей сервиса
                        </h2>
                        <div class="reviews__grid">
                            {
                                reviews.map((review, index) => {
                                    return(
                                        <ReviewsItems key={index} review={review}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default Reviews;