function Auth({children}){
    return (
        <div class="wrapper_login">
            {/* <div class="login_r_block">

            </div> */}
            <div class="schedule">
                <div class="filler_shedule">
                    <div class="elipse index_elipse2">

                    </div>
                    <div class="elipse index_elipse4">

                    </div>
                    <div class="elipse indexelipse3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="175" height="110" viewBox="0 0 175 110" fill="none">
                            <circle cx="87.5" cy="87.5" r="87.5" fill="#2D61EB" />
                        </svg>
                    </div>
                    <div class="elipse index_elipse5">

                    </div>
                    <div class="elipse index_elipse6">

                    </div>
                </div>
            </div>
            {children}
        </div>
    )
}

export default Auth;