import { toast } from 'react-toastify';


export const showOpenDeal = (up, summ, coin_name) => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
        width: '85%',
        marginLeft: 'auto',
        justifyContent: 'normal',
      }}>
        <div class="icon_toastr_deal">
        </div>
        <div class="text_toastr_deal">
            <div style={{display: 'flex', gap: '8px'}}>
                <span class="text_deal_block_toastr">
                Сделка открыта
                </span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 6.54184C2.65912 4.34504 4.34504 2.65912 6.54184 2.36899C8.05208 2.16953 9.94127 2 12 2C14.0587 2 15.9479 2.16953 17.4582 2.36899C19.655 2.65912 21.3409 4.34504 21.631 6.54184C21.8305 8.05208 22 9.94127 22 12C22 14.0587 21.8305 15.9479 21.631 17.4582C21.3409 19.655 19.655 21.3409 17.4582 21.631C15.9479 21.8305 14.0587 22 12 22C9.94127 22 8.05208 21.8305 6.54184 21.631C4.34504 21.3409 2.65912 19.655 2.36899 17.4582C2.16953 15.9479 2 14.0587 2 12C2 9.94127 2.16953 8.05208 2.36899 6.54184Z" fill="#3E991E"/>
                <path d="M10.8512 17C11.2123 17 11.5186 16.823 11.7264 16.4801L16.7264 8.52655C16.8577 8.31638 17 8.06194 17 7.81858C17 7.3208 16.5623 7 16.1028 7C15.8184 7 15.5449 7.17699 15.3479 7.49779L10.7964 14.8651L8.64113 12.0443C8.38949 11.6903 8.13785 11.6017 7.84245 11.6017C7.36105 11.6017 7 11.9889 7 12.4756C7 12.719 7.08753 12.9513 7.2407 13.1615L9.91025 16.4801C10.1947 16.8451 10.4792 17 10.8512 17Z" fill="white"/>
                </svg>
            </div>
            <div style={{display: 'flex', gap: '8px'}}>
                <span class="text_deal_block_toastr_big">
                {coin_name}
                </span>
                {
                    up
                    ?
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5Z" fill="#3E991E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289L20.7071 7.29289C21.0976 7.68342 21.0976 8.31658 20.7071 8.70711C20.3166 9.09763 19.6834 9.09763 19.2929 8.70711L19 8.41421V9C19 14.5228 14.5228 19 9 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H9C13.4183 17 17 13.4183 17 9V8.41421L16.7071 8.70711C16.3166 9.09763 15.6834 9.09763 15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289L17.2929 5.29289Z" fill="white"/>
                    </svg>
                    :
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5Z" fill="#CC0000"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071L20.7071 16.7071C21.0976 16.3166 21.0976 15.6834 20.7071 15.2929C20.3166 14.9024 19.6834 14.9024 19.2929 15.2929L19 15.5858V15C19 9.47715 14.5228 5 9 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H9C13.4183 7 17 10.5817 17 15V15.5858L16.7071 15.2929C16.3166 14.9024 15.6834 14.9024 15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071L17.2929 18.7071Z" fill="white"/>
                    </svg>
                }

            </div>
            <div class="info_deal_text_toastr">
                <div class="info_deal_text_toastr_block">
                    <span class="text_deal_block_toastr">
                    Прогноз
                    </span>
                    <div style={{display: 'flex', gap: '8px'}}>
                        <span class="text_deal_block_toastr">
                            {
                                up ? 'Выше'
                                : 'Ниже'
                            }
                        </span>
                    </div>
                </div>
                <div style={{
                    width: '1px',
                    background: 'rgba(255, 255, 255, 1)'
                }}>

                </div>
                <div class="info_deal_text_toastr_block">
                    <span class="text_deal_block_toastr">
                    Сумма
                    </span>
                    <div style={{display: 'flex', gap: '8px'}}>
                        <span class="text_deal_block_toastr"><span class="sign_balance">₽</span>{summ}</span>
                    </div>
                </div>
                </div>
        </div>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );

  export const showError = (text) => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
      }}>
        <div class="icon_toastr_error">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 7.04184C2.65912 4.84504 4.34504 3.15912 6.54184 2.86899C8.05208 2.66953 9.94127 2.5 12 2.5C14.0587 2.5 15.9479 2.66953 17.4582 2.86899C19.655 3.15912 21.3409 4.84504 21.631 7.04184C21.8305 8.55208 22 10.4413 22 12.5C22 14.5587 21.8305 16.4479 21.631 17.9582C21.3409 20.155 19.655 21.8409 17.4582 22.131C15.9479 22.3305 14.0587 22.5 12 22.5C9.94127 22.5 8.05208 22.3305 6.54184 22.131C4.34504 21.8409 2.65912 20.155 2.36899 17.9582C2.16953 16.4479 2 14.5587 2 12.5C2 10.4413 2.16953 8.55208 2.36899 7.04184Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.79289C8.68342 8.40237 9.31658 8.40237 9.70711 8.79289L12 11.0858L14.2929 8.79289C14.6834 8.40237 15.3166 8.40237 15.7071 8.79289C16.0976 9.18342 16.0976 9.81658 15.7071 10.2071L13.4142 12.5L15.7071 14.7929C16.0976 15.1834 16.0976 15.8166 15.7071 16.2071C15.3166 16.5976 14.6834 16.5976 14.2929 16.2071L12 13.9142L9.70711 16.2071C9.31658 16.5976 8.68342 16.5976 8.29289 16.2071C7.90237 15.8166 7.90237 15.1834 8.29289 14.7929L10.5858 12.5L8.29289 10.2071C7.90237 9.81658 7.90237 9.18342 8.29289 8.79289Z" fill="white"/>
        </svg>

        </div>
        <p class="text_toastr" style={{'padding': '16px'}}>
        {text}
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );
  export const showExpiredDeposit = () => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
      }}>
        <div class="icon_toastr_error">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_522_572)">
        <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M6.30179 8.91477C6.13289 7.95563 6 6.91137 6 6V0.5H18V6C18 6.91137 17.8671 7.95563 17.6982 8.91477C17.4137 10.5303 16.3562 11.8787 14.895 12.5C16.3562 13.1213 17.4137 14.4697 17.6982 16.0852C17.8671 17.0444 18 18.0886 18 19V24.5H6V19C6 18.0886 6.13289 17.0444 6.30179 16.0852C6.58627 14.4697 7.64383 13.1213 9.10503 12.5C7.64383 11.8787 6.58627 10.5303 6.30179 8.91477Z" fill="white"/>
        <path d="M4 1.5C4 0.947715 4.44772 0.5 5 0.5H19C19.5523 0.5 20 0.947715 20 1.5C20 2.05228 19.5523 2.5 19 2.5H5C4.44772 2.5 4 2.05228 4 1.5Z" fill="white"/>
        <path d="M4 23.5C4 22.9477 4.44772 22.5 5 22.5H19C19.5523 22.5 20 22.9477 20 23.5C20 24.0523 19.5523 24.5 19 24.5H5C4.44772 24.5 4 24.0523 4 23.5Z" fill="white"/>
        <path d="M8 22.5C8 21.8871 8.34626 21.3269 8.89443 21.0528L11.1056 19.9472C11.6686 19.6657 12.3314 19.6657 12.8944 19.9472L15.1056 21.0528C15.6537 21.3269 16 21.8871 16 22.5H8Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_522_572">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
        </div>
        <p class="text_toastr">
        Время депозита истекло
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );
export const showInfo = (text) => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
      }}>
        <div class="icon_toastr_info">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.25" cx="12" cy="12" r="10" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C11.4477 7 11 7.44772 11 8C11 8.55229 11.4477 9 12 9C12.5523 9 13 8.55229 13 8C13 7.44772 12.5523 7 12 7ZM12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12C13 11.4477 12.5523 11 12 11Z" fill="white"/>
        </svg>

        </div>
        <p class="text_toastr" style={{'padding': '16px'}}>
        {text}
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );
  
  export const showDone = (text) => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
      }}>
        <div class="icon_toastr">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 7.04184C2.65912 4.84504 4.34504 3.15912 6.54184 2.86899C8.05208 2.66953 9.94127 2.5 12 2.5C14.0587 2.5 15.9479 2.66953 17.4582 2.86899C19.655 3.15912 21.3409 4.84504 21.631 7.04184C21.8305 8.55208 22 10.4413 22 12.5C22 14.5587 21.8305 16.4479 21.631 17.9582C21.3409 20.155 19.655 21.8409 17.4582 22.131C15.9479 22.3305 14.0587 22.5 12 22.5C9.94127 22.5 8.05208 22.3305 6.54184 22.131C4.34504 21.8409 2.65912 20.155 2.36899 17.9582C2.16953 16.4479 2 14.5587 2 12.5C2 10.4413 2.16953 8.55208 2.36899 7.04184Z" fill="white"/>
                <path d="M10.8512 17.5C11.2123 17.5 11.5186 17.323 11.7264 16.9801L16.7264 9.02655C16.8577 8.81638 17 8.56194 17 8.31858C17 7.8208 16.5623 7.5 16.1028 7.5C15.8184 7.5 15.5449 7.67699 15.3479 7.99779L10.7964 15.3651L8.64113 12.5443C8.38949 12.1903 8.13785 12.1017 7.84245 12.1017C7.36105 12.1017 7 12.4889 7 12.9756C7 13.219 7.08753 13.4513 7.2407 13.6615L9.91025 16.9801C10.1947 17.3451 10.4792 17.5 10.8512 17.5Z" fill="white"/>
            </svg>
        </div>
        <p class="text_toastr" style={{'padding': 16+'px'}}>
            {text}
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );

  export const showWaitPay=()=>toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
      }}>
        <div class="icon_toastr">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M6.30179 8.41477C6.13289 7.45563 6 6.41137 6 5.5V0H18V5.5C18 6.41137 17.8671 7.45563 17.6982 8.41477C17.4137 10.0303 16.3562 11.3787 14.895 12C16.3562 12.6213 17.4137 13.9697 17.6982 15.5852C17.8671 16.5444 18 17.5886 18 18.5V24H6V18.5C6 17.5886 6.13289 16.5444 6.30179 15.5852C6.58627 13.9697 7.64383 12.6213 9.10503 12C7.64383 11.3787 6.58627 10.0303 6.30179 8.41477Z" fill="white"/>
            <path d="M4 1C4 0.447715 4.44772 0 5 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H5C4.44772 2 4 1.55228 4 1Z" fill="white"/>
            <path d="M4 23C4 22.4477 4.44772 22 5 22H19C19.5523 22 20 22.4477 20 23C20 23.5523 19.5523 24 19 24H5C4.44772 24 4 23.5523 4 23Z" fill="white"/>
            <path d="M8 22C8 21.3871 8.34626 20.8269 8.89443 20.5528L11.1056 19.4472C11.6686 19.1657 12.3314 19.1657 12.8944 19.4472L15.1056 20.5528C15.6537 20.8269 16 21.3871 16 22H8Z" fill="white"/>
        </svg>

        </div>
        <p class="text_toastr" style={{'padding': 16+'px'}}>
        Ваша заявка принята!
        <br/>
        Ожидайте подтверждения оплаты
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );

export const showYouWin = (summ) => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
        width: '80%',
        marginLeft: 'auto',
      }}>
        <div class="icon_toastr">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 6.54184C2.65912 4.34504 4.34504 2.65912 6.54184 2.36899C8.05208 2.16953 9.94127 2 12 2C14.0587 2 15.9479 2.16953 17.4582 2.36899C19.655 2.65912 21.3409 4.34504 21.631 6.54184C21.8305 8.05208 22 9.94127 22 12C22 14.0587 21.8305 15.9479 21.631 17.4582C21.3409 19.655 19.655 21.3409 17.4582 21.631C15.9479 21.8305 14.0587 22 12 22C9.94127 22 8.05208 21.8305 6.54184 21.631C4.34504 21.3409 2.65912 19.655 2.36899 17.4582C2.16953 15.9479 2 14.0587 2 12C2 9.94127 2.16953 8.05208 2.36899 6.54184Z" fill="white"/>
        <path d="M10.8512 17C11.2123 17 11.5186 16.823 11.7264 16.4801L16.7264 8.52655C16.8577 8.31638 17 8.06194 17 7.81858C17 7.3208 16.5623 7 16.1028 7C15.8184 7 15.5449 7.17699 15.3479 7.49779L10.7964 14.8651L8.64113 12.0443C8.38949 11.6903 8.13785 11.6017 7.84245 11.6017C7.36105 11.6017 7 11.9889 7 12.4756C7 12.719 7.08753 12.9513 7.2407 13.1615L9.91025 16.4801C10.1947 16.8451 10.4792 17 10.8512 17Z" fill="white"/>
        </svg>


        </div>
        <p class="text_toastr">
        Вы выиграли!
        <br/>
        +
        <span class="sign_balance">₽</span>
        {summ}
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );

export const showYouLose = (summ) => toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
        width: '80%',
        marginLeft: 'auto',
      }}>
        <div class="icon_toastr_error">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 7.04184C2.65912 4.84504 4.34504 3.15912 6.54184 2.86899C8.05208 2.66953 9.94127 2.5 12 2.5C14.0587 2.5 15.9479 2.66953 17.4582 2.86899C19.655 3.15912 21.3409 4.84504 21.631 7.04184C21.8305 8.55208 22 10.4413 22 12.5C22 14.5587 21.8305 16.4479 21.631 17.9582C21.3409 20.155 19.655 21.8409 17.4582 22.131C15.9479 22.3305 14.0587 22.5 12 22.5C9.94127 22.5 8.05208 22.3305 6.54184 22.131C4.34504 21.8409 2.65912 20.155 2.36899 17.9582C2.16953 16.4479 2 14.5587 2 12.5C2 10.4413 2.16953 8.55208 2.36899 7.04184Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.79289C8.68342 8.40237 9.31658 8.40237 9.70711 8.79289L12 11.0858L14.2929 8.79289C14.6834 8.40237 15.3166 8.40237 15.7071 8.79289C16.0976 9.18342 16.0976 9.81658 15.7071 10.2071L13.4142 12.5L15.7071 14.7929C16.0976 15.1834 16.0976 15.8166 15.7071 16.2071C15.3166 16.5976 14.6834 16.5976 14.2929 16.2071L12 13.9142L9.70711 16.2071C9.31658 16.5976 8.68342 16.5976 8.29289 16.2071C7.90237 15.8166 7.90237 15.1834 8.29289 14.7929L10.5858 12.5L8.29289 10.2071C7.90237 9.81658 7.90237 9.18342 8.29289 8.79289Z" fill="white"/>
        </svg>

        </div>
        <p class="text_toastr">
        Вы проиграли
        <br/>
        -
        <span class="sign_balance">₽</span>
        {summ}
        </p>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );

export const showDealClose = (sign, summ, profit, coin_name, up) => {
    toast(
    <div class="toastr_popup" style={{ 
        backgroundColor: 'rgba(29, 30, 34, 1)', 
        border: '1px solid',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
        borderImageSlice: '1',
        width: '85%',
        marginLeft: 'auto',
        justifyContent: 'normal',
      }}>
        <div class="icon_toastr_deal">
        </div>
        <div class="text_toastr_deal">
            <div style={{display: 'flex', gap: '8px'}}>
                <span class="text_deal_block_toastr">
                Сделка закрыта
                </span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 6.54184C2.65912 4.34504 4.34504 2.65912 6.54184 2.36899C8.05208 2.16953 9.94127 2 12 2C14.0587 2 15.9479 2.16953 17.4582 2.36899C19.655 2.65912 21.3409 4.34504 21.631 6.54184C21.8305 8.05208 22 9.94127 22 12C22 14.0587 21.8305 15.9479 21.631 17.4582C21.3409 19.655 19.655 21.3409 17.4582 21.631C15.9479 21.8305 14.0587 22 12 22C9.94127 22 8.05208 21.8305 6.54184 21.631C4.34504 21.3409 2.65912 19.655 2.36899 17.4582C2.16953 15.9479 2 14.0587 2 12C2 9.94127 2.16953 8.05208 2.36899 6.54184Z" fill="#CC0000"/>
                <path d="M8.85116 17C9.21228 17 9.51861 16.823 9.72643 16.4801L14.7264 8.52655C14.8577 8.31638 15 8.06194 15 7.81858C15 7.3208 14.5623 7 14.1028 7C13.8184 7 13.5449 7.17699 13.3479 7.49779L8.79645 14.8651L6.64113 12.0443C6.38949 11.6903 6.13785 11.6017 5.84245 11.6017C5.36105 11.6017 5 11.9889 5 12.4756C5 12.719 5.08753 12.9513 5.2407 13.1615L7.91025 16.4801C8.19473 16.8451 8.4792 17 8.85116 17Z" fill="white"/>
                <path d="M12.8512 17C13.2123 17 13.5186 16.823 13.7264 16.4801L18.7264 8.52655C18.8577 8.31638 19 8.06194 19 7.81858C19 7.3208 18.5623 7 18.1028 7C17.8184 7 17.5449 7.17699 17.3479 7.49779L12.7964 14.8651L10.6411 12.0443C10.3895 11.6903 10.1379 11.6017 9.84245 11.6017C9.36105 11.6017 9 11.9889 9 12.4756C9 12.719 9.08753 12.9513 9.2407 13.1615L11.9103 16.4801C12.1947 16.8451 12.4792 17 12.8512 17Z" fill="white"/>
                </svg>
            </div>
            <div style={{display: 'flex', gap: '8px'}}>
                <span class="text_deal_block_toastr_big">
                {coin_name}
                </span>
                {
                  up
                  ?
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5Z" fill="#3E991E"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289L20.7071 7.29289C21.0976 7.68342 21.0976 8.31658 20.7071 8.70711C20.3166 9.09763 19.6834 9.09763 19.2929 8.70711L19 8.41421V9C19 14.5228 14.5228 19 9 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H9C13.4183 17 17 13.4183 17 9V8.41421L16.7071 8.70711C16.3166 9.09763 15.6834 9.09763 15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289L17.2929 5.29289Z" fill="white"/>
                  </svg>
                  :
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5Z" fill="#CC0000"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071L20.7071 16.7071C21.0976 16.3166 21.0976 15.6834 20.7071 15.2929C20.3166 14.9024 19.6834 14.9024 19.2929 15.2929L19 15.5858V15C19 9.47715 14.5228 5 9 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H9C13.4183 7 17 10.5817 17 15V15.5858L16.7071 15.2929C16.3166 14.9024 15.6834 14.9024 15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071L17.2929 18.7071Z" fill="white"/>
                  </svg>
                }

            </div>
            <div class="info_deal_text_toastr">
                <div class="info_deal_text_toastr_block">
                    <span class="text_deal_block_toastr">
                    Выплата
                    </span>
                    <div style={{display: 'flex', gap: '8px'}}>
                        <span class="text_deal_block_toastr_big">{sign}92<span class="sign_balance">%</span></span>
                        <span class="text_deal_block_toastr" style={{
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}>+<span class="sign_balance">₽</span>{summ}</span>
                    </div>
                </div>
                <div style={{
                    width: '1px',
                    background: 'rgba(255, 255, 255, 1)'
                }}>

                </div>
                <div class="info_deal_text_toastr_block">
                    <span class="text_deal_block_toastr">
                    Прибыль
                    </span>
                    <div style={{display: 'flex', gap: '8px'}}>
                        <span class="text_deal_block_toastr_big">+<span class="sign_balance">₽</span>{profit}</span>
                        
                    </div>
                </div>
                </div>
        </div>
    </div>
    , 
    {
      closeButton: false,
      autoClose: 3000,
      hideProgressBar: true,
    }
  );}