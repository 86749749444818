import Auth from "../components/Auth";
import logo from "../assets/images/logo.svg"
import sign_up_confirm from "../assets/images/sign-up-confirm.svg"
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../constanta/apiConfig";
import { showError, showDone } from "../constanta/Toastr";

function SignUpConfirm(){
    const {auth, setAuth} = useContext(AuthContext)
    const [linkTg, setLinkTg] = useState()

    const navigate = useNavigate();

    useEffect(() => {
        setAuth({...auth, loading: true});
        axios.post(`${BASE_URL}/api/sign-up-get`,{
            login: localStorage.getItem('login')
        }, { withCredentials: true })
        .then(res => {
            
            if(res.data.status == "ok"){
                setLinkTg(res.data.link)
            }else if(res.data.status == "error"){
                showError("Ошибка")
                navigate("/sign-up")
            }
            setAuth({...auth, loading: false})
        })

        const interval = setInterval(chekConfirm, 2000);

        return () => clearInterval(interval);
      }, []);

      const chekConfirm = ()=> {
        axios.post(`${BASE_URL}/api/sign-up-chek`,{
            login: localStorage.getItem('login')
        }, { withCredentials: true })
        .then(res => {
            if(res.data.status == "ok"){
                if(res.data.confirm){
                    showDone("Вы успешно зарегистрировались")
                    navigate("/")
                }
            }else if(res.data.status == "error"){
                showError("Ошибка")
                navigate("/sign-up")
            }
        })
      }
      
    return (
        <Auth>
            <div class="index_login">
                <div class="modal__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div class="modal__title">
                    <span class="modal__title-line"></span>
                    <span class="modal__title-text">Завершение регистрации</span>
                    <span class="modal__title-line"></span>
                </div>
                <p class="modal__text">
                    Автоматическая регистрация
                </p>
                <p class="modal__text modal__text_mt-12">
                    Нажмите кнопку <a class="modal__link modal__link_color_blue modal__link_fz_18" href={linkTg} target="_blank">Завершить
                        регистрацию </a>
                    и перейдите в Telegram.
                </p>
                <div class="modal__btns">
                    <a class="btn btn_background_blue modal__btn" id="link-verification-code"  href={linkTg} target="_blank">
                        <img src={sign_up_confirm} alt="icon"/>
                        Завершить регистрацию
                    </a>
                </div>
                <p class="modal__text modal__text_mt-40">
                    Ручная регистрация
                </p>
                <p class="modal__text modal__text_mt-12">
                    Для завершения подачи заявки напишите боту
                    <a class="modal__link modal__link_color_blue modal__link_fz_18" id="link-bot-tag" href=""> </a>
                    следующее сообщение:
                </p>
                <input class="input modal__input_mt-24" id="input-verification-code" type="text" value={linkTg} readonly=""/>
            </div>
        </Auth>
    )
}

export default SignUpConfirm;