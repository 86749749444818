import Auth from "../components/Auth";
import logo from "../assets/images/logo.svg"
import sign_in from "../assets/images/sign-in.svg"
import sign_up from "../assets/images/sign-up.svg"
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context";
import axios from "axios";
import { BASE_URL } from "../constanta/apiConfig";
import { showError } from "../constanta/Toastr";

function SignUp(){
    const {auth, setAuth} = useContext(AuthContext)
    const [value, setValue] = useState('')

    const navigate = useNavigate();
    
    useEffect(() => {
        setAuth({...auth, loading: true});
        const timer = setTimeout(() => setAuth({...auth, loading: false}), 500);
        return () => clearTimeout(timer);
      }, []);
    
    const chekLogin = ()=> {
        var pattern =  /^[a-zA-Z0-9]{4,16}$/;
        if(value== '' || !pattern.test(value)){
            showError('Логин должен содержать от 4 до 16 символов и состоять только из латинских букв и цифр.');
        }else{
            setAuth({...auth, loading: true});
            axios.post(`${BASE_URL}/api/sign-up`,{
                login: value,
                ref: localStorage.getItem('ref')
            }, { withCredentials: true })
            .then(res => {
                if(res.data.status == 'login error'){
                    showError('Логин занят')
                    setAuth({...auth, loading: false});
                }else if(res.data.status == 'ok'){
                    localStorage.setItem('login', res.data.login)
                    navigate("/sign-up-confirm")
                }else if(res.data.status == 'login empty'){
                    showError('Уведите логин')
                    setAuth({...auth, loading: false});
                }else{
                    showError('Ошибка')
                    setAuth({...auth, loading: false});
                }
            })
            .catch(()=>{
                showError('Ошибка')
                setAuth({...auth, loading: false});
            })
        }
    }
    

    return (
        <Auth>
            <div class="index_login">
                <div class="modal__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div class="modal__title">
                    <span class="modal__title-line"></span>
                    <span class="modal__title-text">Регистрация</span>
                    <span class="modal__title-line"></span>
                </div>
                <form class="modal__form" id="sign-up-form">
                    <label class="label modal__label">Логин</label>
                    <input class="input modal__input" type="text" name="login" value={value} onChange={event=>setValue(event.target.value)} placeholder="Введите логин"/>

                </form>
                <div class="modal__btns">
                    <a class="btn btn_background_white modal__btn" id="sign-up-btn" onClick={()=>chekLogin()}>
                        <img src={sign_up} alt="icon"/>
                        Создать аккаунт
                    </a>
                    <Link class="btn btn_background_blue modal__btn open-modal" modal-name="sign-in" to="/login">
                        <img src={sign_in} alt="icon"/>
                        Войти
                    </Link>
                </div>
            </div>
        </Auth>
    )
}

export default SignUp;