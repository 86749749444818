import Auth from "../components/Auth";
import logo from "../assets/images/logo.svg"
import restore_password from "../assets/images/restore-password.svg"
import { Link } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context";
import axios from "axios";
import { BASE_URL } from "../constanta/apiConfig";

function ResetPassword(){
    const {auth, setAuth} = useContext(AuthContext)
    const [linkTgBot, setLinkTgBot] = useState()

    useEffect(() => {
        setAuth({...auth, loading: true});
        axios.post(`${BASE_URL}/api/get-link-tg`,{}, { withCredentials: true })
            .then(res => {
                setLinkTgBot(res.data.bot)
                setAuth({...auth, loading: false});
            })
      }, []);
    return (
        <Auth>
            <div class="index_login">
                <div class="modal__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div class="modal__title">
                    <span class="modal__title-line"></span>
                    <span class="modal__title-text">Восстановление пароля</span>
                    <span class="modal__title-line"></span>
                </div>
                <p class="modal__text modal__text_mt-12">
                    Для восстановления пароля напишите <a class="modal__link modal__link_color_blue modal__link_fz_18" href={linkTgBot} target="_blank"><br/>Забыл пароль</a> боту
                    в TELEGRAM
                </p>
                <div class="modal__btns">
                    <a class="btn btn_background_blue modal__btn" href={linkTgBot} target="_blank">
                        <img src={restore_password} alt="icon"/>
                        Восстановить пароль
                    </a>
                </div>
                <Link class="modal__link" to="/login">Вернуться</Link>
            </div>
        </Auth>
    )
}

export default ResetPassword;