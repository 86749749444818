import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";
import Index from "./pages/Index";
import Faq from "./pages/Faq";
import History from "./pages/History";
import Reviews from "./pages/Reviews";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import SignUpConfirm from "./pages/SignUpConfirm";
import Loader from "./components/Loader";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "./context";
import Logout from "./pages/Logout";
import axios from "axios";
import { BASE_URL } from "./constanta/apiConfig";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [auth, setAuth] = useState({isAuth: false, verification: false, login: 'username', id: '1347911', data: '1 октября 2023 г. в 06:48', balance: 100, loading: false})

  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const paramValue = urlParams.get('ref');

    if (paramValue) {
      localStorage.setItem('ref', paramValue);
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      auth,
      setAuth
    }}>
      <BrowserRouter>
        {auth.loading && <Loader />}
          
            <Routes>
              <Route path="/" element={<CheckAuth><Index/></CheckAuth>}/>
              <Route path="/faq" element={<CheckAuth><Faq/></CheckAuth>} />
              <Route path="/history" element={<CheckAuth><History/></CheckAuth>} />
              <Route path="/reviews" element={<CheckAuth><Reviews/></CheckAuth>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/reset-password" element={<ResetPassword/>} />
              <Route path="/sign-up" element={<SignUp/>} />
              <Route path="/sign-up-confirm" element={<SignUpConfirm/>} />
              <Route path="/logout" element={<Logout/>}/>
            </Routes>
          
      </BrowserRouter>
      <ToastContainer/>
    </AuthContext.Provider>  
  );
}

function CheckAuth({ children }) {
  const { auth, setAuth } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios.post(`${BASE_URL}/api/get-info-user`,{}, { withCredentials: true })
    .then(res => {
      const datas = res.data;
      console.log(datas)
      setAuth({...auth, verification: datas.verification, login: datas.login, data: datas.date, balance: datas.balance, id: datas.id_tg, isAuth: true})
      if (['/login', '/reset-password', '/sign-up', '/sign-up-confirm'].includes(location.pathname)) {
        navigate('/');
      }
    })
    .catch(()=>{
      setAuth({...auth, isAuth: false})
      if (!['/login', '/reset-password', '/sign-up', '/sign-up-confirm'].includes(location.pathname)) {
        navigate('/login');
      }
    })
    .finally(() => setIsLoading(true));
    
  }, [location, navigate]);
  if(isLoading){
    return children ? children : null;
  }
}

export default App;
